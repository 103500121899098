@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("./assets/fonts/Gotham-Medium.otf") format("opentype");
}
@font-face {
  font-family: "GothamBold";
  src: local("GothamBold"),
    url("./assets/fonts/Gotham-Bold.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "GothamBook";
  src: local("GothamBook"),
    url("./assets/fonts/Gotham-Book.otf") format("opentype");
  font-weight: lighter;
}
@font-face {
  font-family: "Lato";
  src: local("Lato"), url("./assets/fonts/Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "LatoBold";
  src: local("LatoBold"), url("./assets/fonts/Lato-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Collection";
  src: local("Collection"),
    url("./assets/fonts/CollectionFree.otf") format("opentype");
}

body {
  margin: 0;
  font-family: Lato;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
