* {
  margin: 0;
  padding: 0;
}

p {
  font-family: Lato;
  font-size: clamp(12px, 0.7rem, 16px);
  color: #2b2e34;
}

h1 {
  font-size: clamp(16px, 2rem, 42px);
  font-size: 1.8rem;
  font-family: "GothamBold";

  background: -webkit-linear-gradient(45deg, #33ccff, #336699);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h2 {
  font-size: clamp(20px, 1.2rem, 34px);
  font-size: 1.2rem;
  color: #a5a5a5;
  font-family: "GothamBook";
}

h3 {
  font-size: clamp(20px, 1.2rem, 34px);
  font-size: 1rem;
  font-family: "Gotham";
  color: #24659f;
}

h4 {
  color: #a5a5a5;
  font-family: "GothamBook";
  font-size: clamp(16px, 1rem, 24px);
  font-size: 0.9rem;
}

h5 {
  color: "#33ccff";
  font-family: "GothamBold";
}

:root {
  background: #36454f;
}

.App {
  background: #36454f;
  max-width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  overflow: visible;
}

.pageWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  background: #f4f4f4;
  overflow: hidden;
}

.pageContent {
  margin: clamp(2em, 8%, 74px);
  display: flex;
  flex-direction: "column";
}

/* ===== Nav Styling =====  */

nav {
  font-size: 14px;
  cursor: pointer;
}

nav:hover {
  opacity: 0.5;
  cursor: pointer;
}

.menuIcon:hover {
  width: 30px;
  opacity: 0.5;
}

.card {
  padding: 1.5em;
  padding-top: clamp(2em, 8vh, 74px);
}

/* ===== Book Styling ===== */
.book {
  position: relative;
  aspect-ratio: 2.29;
  flex: 1;
  max-width: calc(100vw - 2em);
  max-height: calc(100vh - 4.5em);
  margin: 1em;
  margin-top: 3.5em;
  min-width: calc(1160px - 2em);
  min-height: 492px;
  width: calc(100vw - 2em);
}

.page {
  position: absolute;
  aspect-ratio: 1.145;
  height: 100%;
  top: 0;
  right: 0;
  -webkit-transform-origin: left;
  transform-origin: left;
}

.front,
.back {
  background: white;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-transform-origin: left;
  transform-origin: left;
}

.front {
  z-index: 1;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  /* opacity: 0.5; */
}

.back {
  z-index: 0;
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
}

.content {
  height: 100%;
  width: 100%;
}

.back > .content {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  /* border-right: 1px solid #33669955; */
}

.page-number {
  position: absolute;
  background: -webkit-linear-gradient(45deg, #336699, #33ccff);
  bottom: 0;
  margin-bottom: calc(clamp(2em, 8%, 74px) - 2rem);
  width: 100%;
  height: 2rem;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 100;
}

.page-number-text {
  font-size: 1.4rem;
  margin-right: 1em;
  color: white;
  font-family: Gotham;
}

@media (max-width: 1200px) {
  .book .pageContent {
    margin: clamp(1em, 5%, 74px);
    display: flex;
    flex-direction: column;
  }

  .card {
    padding: 1em;
    padding-top: clamp(1em, 5%, 74px);
  }
}

@media (max-width: 900px) {
  p {
    font-size: 10px;
  }

  h1 {
    font-size: 14px;
  }

  h2 {
    font-size: 13px;
  }

  h3 {
    font-size: 13px;
  }

  h4 {
    font-size: 12px;
  }

  h5 {
    font-size: 12px;
  }

  .book {
    margin: 0;
    margin-top: 1.5em;
    min-width: 750px;
    min-height: 0px;
    max-height: 200vh;
  }

  .page-number {
    height: 0.8rem;
    margin-bottom: calc(clamp(1em, 5%, 74px) - 0.8rem);
  }

  .page-number-text {
    font-size: 12px;
  }

  .quote-text {
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    max-height: 100%;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
}
